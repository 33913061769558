import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import BlogPostListItemFull from "../components/BlogPostListItemFull"
import { Typography } from "@mui/material"
import CenterColumn from "../components/CenterColumn"

export default class BlogList extends React.Component {
  render() {
    const posts = this.props.data.allContentfulBlogPost.edges
    return (
      <Layout>
        <CenterColumn>
          <Typography variant="h2">Blog</Typography>
          {posts.map(({ node }) => {
            return <BlogPostListItemFull {...node} key={node.id} />
          })}
        </CenterColumn>
      </Layout>
    )
  }
}

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allContentfulBlogPost(
      sort: { fields: [publishDate], order: ASC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          slug
          id
          publishDate
          description {
            childMarkdownRemark {
              html
            }
          }
          thumbnail {
            gatsbyImageData(layout: CONSTRAINED, width: 1000)
          }
        }
      }
    }
  }
`
